import I18n from './../vendor/I18n.js';

const translator = new I18n;

function trans(key, replace = {}) {
    return translator.trans(key, replace);
}

function __(key, replace = {}) {
    return trans(key, replace);
}

function trans_choice(key, count = 1, replace = {}) {
    return translator.trans_choice(key, count, replace);
}

export {
    __,
    trans,
    trans_choice
};
